#home {
  /* background: url("./imgs/home/homeBackground.jpg") no-repeat; */
  @apply bg-cover bg-center;
}

#contact {
  position: relative; /* #wrapper에 투명도를 주면 컨텐츠도 같이 투명해지기 때문에.. */
}

#contact:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url("./imgs/contact/bg_contact.jpg") center/cover no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
}

.worksidebar-li-default {
  @apply block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-500 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300;
}

.worksidebar-li-active {
  @apply block border-l pl-4 -ml-px text-indigo-300 border-current font-semibold dark:text-sky-400;
}

.img__wrap {
  width: 100%;
  margin: 10px auto;
  position: relative;
}

.img__wrap img {
  width: 100%;
  vertical-align: middle;
}

.img__text {
  position: absolute;
  top: 98%;
  left: 50%;
  width: 100%;
  height: 45px;
  transform: translate(-50%, -50%);
  font-size: 15px;
  text-align: right;
  padding-top: 10px;
  padding-right: 20px;
  background-color: #c0c0c0;
  opacity: 0.9;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 280px;
  height: 250px; */
  /* margin: 2px; */
  background-color: var(--color-light-white);
}

.project.invisible {
  display: none;
}

.project__img {
  width: 100%;
  height: 100%;
}

.project__description {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6%;
  background-color: black;
  opacity: 0;
  transform: translateY(15px);
  transition: all var(--animation-duration) ease-in;
  color: white;
  text-align: right;
  padding-right: 15px;
}

.project__description_vertical {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4%;
  background-color: black;
  opacity: 0;
  transform: translateY(15px);
  transition: all var(--animation-duration) ease-in;
  color: white;
  text-align: right;
  padding-right: 15px;
}

.project:hover .project__description {
  transform: translateY(0px);
  opacity: 0.5;
}

.project:hover .project__description_vertical {
  transform: translateY(0px);
  opacity: 0.5;
}

.mirroring__font {
  font-size: 10px;
}
